











import Vue from 'vue';
import Service from '../services/customers';
import VSelect from '@alfsnd/vue-bootstrap-select';
import _ from 'lodash';

export default Vue.extend({
  name: 'ComboObjet',
  props: {
    id: String,
    title: String,
    ssTitle: String,
    customer: Object,
    isDisabled: Boolean,
    options: Array,
  },
  components: {
    VSelect,
  },
  data() {
    return {
      src: '/img/icons/logo.png',
      customerId: 'admin',
      comboValue: null,
      name: String,
      items: false,
    };
  },
  mounted() {
    this.setImage();
    this.items = _.map(this.options, 'title');
  },
  watch: {
    comboValue: function(newId) {
      if (_.isObject(newId) || newId == null) return;

      let response = _.filter(this.options, function(o) {
        return o.title === newId;
      });
      response = response.map(c => ({
        id: c.id,
        title: c.title,
      }));

      if (_.size(response) == 0) return;
      this.src = '/img/icons/' + _.kebabCase(newId) + '.png';
      const data = { id: localStorage.customerId, name: localStorage.customerId };
      data[this.id] = response;
      this.postItem(data);
    },
    customer: function() {
      this.setImage();
    },
  },
  methods: {
    setImage() {
      if (this.customer['squads'][0] != undefined && _.isObject(this.customer['squads'])) {
        this.src = '/img/icons/' + _.kebabCase(this.customer['squads'][0].title) + '.png';
        this.comboValue = this.customer['squads'][0].title;
      } else {
        this.src = '/img/icons/logo.png';
        this.comboValue = null;
      }
    },
    async postItem(data) {
      await Service.put('customers', data).then();
    },
  },
});
