<template>
  <div class="container-fluid">
    <Sub :customerId="customerId"></Sub>
    <div class="row mt-3">
      <div class="col-xl-5 col-lg-6 col-md-6 combo-card">
        <Combo
          v-if="customer"
          id="cloud"
          title="Cloud provider"
          ssTitle="Quel est le Cloud du projet ?"
          :customer="customer"
          :options="cloudOptions"
          :isDisabled="!isAdmin"
        ></Combo>
      </div>
      <div class="col-xl-5 col-lg-6 col-md-6 combo-card">
        <Combo
          v-if="customer"
          id="stack"
          title="Stack de monitoring"
          ssTitle="Quelle solution est mise en oeuvre ?"
          :customer="customer"
          :options="stackOptions"
          :isDisabled="!isAdmin"
        ></Combo>
      </div>
      <div class="col-xl-5 col-lg-6 col-md-6 combo-card">
        <ComboObjet
          v-if="customer"
          id="squads"
          title="Squads"
          ssTitle="A quelle squad appartient ce projet ?"
          :customer="customer"
          :options="squadOptions"
          :isDisabled="!isAdmin"
        ></ComboObjet>
      </div>
      <div class="col-xl-5 col-lg-6 col-md-6 combo-card">
        <b-card class="h-100" v-if="customer" title="Remarque" sub-title="Une information importante sur ce projet ?">
          <b-form-textarea
            class="textarea-size"
            :disabled="!isAdmin"
            v-model="commentValue"
            ref="comment"
            placeholder="Enter something..."
            rows="9"
            max-rows="9"
          ></b-form-textarea>
          <b-button class="mt-3 mb-0" @click="postItem">Sauvegarder</b-button>
          <div class="min-size"></div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Service from '../services/incident';
import SquadService from '../services/squads';
import CustomerService from '../services/customers';
import _ from 'lodash';
import { ObjectiveKind, ObjectiveLabel } from '../types/gitlab';
import Sub from '@/components/layout/Sub.vue';
import Combo from '@/components/Combo.vue';
import ComboObjet from '@/components/ComboObjet.vue';

export default {
  components: {
    Combo,
    ComboObjet,
    Sub,
  },
  data() {
    return {
      commentValue: '',
      isBusy: false,
      cloudOptions: ['AWS', 'GCP', 'AZURE'],
      stackOptions: ['Minotor v3', 'Minotor v4', 'Minotor SL', 'Datadog'],
      squadOptions: [],
      customerId: 'admin',
      isAdmin: localStorage.role == 'admin' ? true : false,
      customer: false,
      title: {
        UNASSIGNED_CHANGE: ObjectiveLabel.UNASSIGNED_CHANGE,
        UNRESOLVED_INCIDENT: ObjectiveLabel.UNRESOLVED_INCIDENT,
        UNSCHEDULED_CHANGE: ObjectiveLabel.UNSCHEDULED_CHANGE,
      },
    };
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    this.getCustomer();
    this.getSquads();
    this.$root.$on('currentClient', data => {
      this.refreshData(data);
    });
  },
  methods: {
    refreshData(a) {
      if (a.customerId) this.customerId = a.customerId;
      this.getCustomer();
    },
    async getCustomer() {
      if (this.customerId == 'admin') return;
      await Service.get('customers/' + this.customerId)
        .then(response => {
          this.customer = response;
          this.commentValue = response.comment;
        })
        .catch(function(error) {
          this.$toastr.e(error);
          console.log(error);
        });
    },
    async getSquads() {
      await SquadService.getAll()
        .then(response => {
          this.squadOptions = response;
        })
        .catch(function(error) {
          this.$toastr.e(error);
          console.log(error);
        });
    },

    postItem() {
      const data = { id: localStorage.customerId, name: localStorage.customerId };
      data['comment'] = this.$refs.comment.localValue;
      CustomerService.put('customers', data).then(response => {
        this.response = response;
        this.$toastr.s('Modification effectée');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  justify-content: center;
}
.combo-card {
  margin-bottom: 1.875rem;
}
.textarea-size {
  margin-top: 30px;
  margin-bottom: 0px;
  height: 200px;
}
</style>
