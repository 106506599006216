











import Vue from 'vue';
import Service from '../services/customers';
import VSelect from '@alfsnd/vue-bootstrap-select';
import _ from 'lodash';

export default Vue.extend({
  name: 'Combo',
  props: {
    id: String,
    title: String,
    ssTitle: String,
    customer: Object,
    isDisabled: Boolean,
    options: Array,
  },
  components: {
    VSelect,
  },
  data() {
    return {
      src: '/img/icons/logo.png',
      customerId: 'admin',
      comboValue: null,
      name: String,
    };
  },
  mounted() {
    this.setImage();
  },
  watch: {
    comboValue: function(newId) {
      if (_.isObject(newId)) return;
      this.comboValue = newId;
      this.src = '/img/icons/' + _.kebabCase(newId) + '.png';
      const data = { id: localStorage.customerId, name: localStorage.customerId };
      data[this.id] = newId;
      this.postItem(data);
    },
    customer: function() {
      this.setImage();
    },
  },
  methods: {
    setImage() {
      if (this.customer[this.id] != undefined && !_.isObject(this.customer[this.id])) {
        this.src = '/img/icons/' + _.kebabCase(this.customer[this.id]) + '.png';
        this.comboValue = this.customer[this.id];
      } else {
        this.src = '/img/icons/logo.png';
        this.comboValue = null;
      }
    },
    async postItem(data) {
      await Service.put('customers', data).then();
    },
  },
});
